$primary: #e42535;
$primary-30: rgba($primary, 0.3);
$primary-10: rgba($primary, 0.1);

$secondary: #1d3658;
$secondary-variant: #447a9c;
$secondary-variant-30: rgba($secondary, 0.3);
$secondary-light: #a8dadc;
$secondary-light-30: rgba($secondary-light, 0.3);
$secondary-light-10: rgba($secondary-light, 0.1);

$background: #eaefe5;
$background-30: rgba($background, 0.3);
$gray: #c4c4c4;

$white: #ffffff;
$white-50: rgba($white, 0.5);

$dark: #201b18;
$error: #ec0000;
$error-10: #fde6e6;

$active-link: #1a81c2;
$active-link-border: rgba($active-link, 0.3);

$hover-footer-link: #e3f0d6;
$hover-footer-link-border: rgba($hover-footer-link, 0.3);

$active-footer-link: #c9e3ae;
$active-footer-link-border: rgba($active-footer-link, 0.3);

$green-50: #f6fbf6;
$green-100: #d4ecd5;
$green-500: #4caf50;
$green-600: #419544;
$sea-green: #43ae5a;
$orange-50: #fff8f2;
$orange-100: #fde0c2;
$orange-500: #f57c00;
$yellow: #ffc700;
$hover-button: #e4331b;
$active-button: #cd2e19;
$iris: #5c4acb;
$blue-100: #cae6fc;
$blue-500: #2196f3;
$blue: #2196f3;
$blue-50: #f4fafe;
$main-orchid: #c75bd9;

$pink-100: #fac9da;
$pink-500: #e91e63;

$fulvous-color: #eb7f00;

$surface-100: #f5f5f5;

$body-bg: $surface-100;

$surface-900: #212121;
$surface-700: #616161;
$surface-600: #757575;
$surface-400: #bdbdbd;
$surface-200: #e0e0e0;
$surface-100: #f5f5f5;
$surface-50: #fafafa;
$surface-0: #fff;

$bluegray-50: #f7f9f9;

$font-source-sans: Source Sans Pro;
$font-inter: Inter;

%body-md {
  font-family: Inter, Source Sans Pro;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

%body-bold {
  @extend %body-md;
  font-weight: 700;
}

%header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px;
}

%h3-md {
  font-family: Inter, Source Sans Pro;
  font-size: 24.5px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  letter-spacing: 0.01em;
}

%h3-bold {
  @extend %h3-md;
  font-weight: 700;
}

%h5-md {
  @extend %h3-md;
  font-size: 17.5px;
  line-height: 26px;
}
