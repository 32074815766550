@import "mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";
@import "variables";

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  2px: 2px,
  10px: 10px,
  12px: 12px,
  20px: 20px,
  32px: 32px,
  40px: 40px,
  48px: 48px,
  55px: 55px,
  56px: 56px,
  58px: 58px,
  64px: 64px,
  72px: 72px,
  88px: 88px,
  94px: 94px,
  111px: 111px,
  unset: unset,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

$utilities: map-merge(
  $utilities,
  (
    "margin": (
      responsive: true,
      property: margin,
      class: m,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-end": (
      responsive: true,
      property: margin-right,
      class: me,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    "margin-start": (
      responsive: true,
      property: margin-left,
      class: ms,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    // Negative margin utilities
    "negative-margin":
      (
        responsive: true,
        property: margin,
        class: m,
        values: $negative-spacers,
      ),
    "negative-margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers,
    ),
    "negative-margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers,
    ),
    "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers,
    ),
    "negative-margin-end": (
      responsive: true,
      property: margin-right,
      class: me,
      values: $negative-spacers,
    ),
    "negative-margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers,
    ),
    "negative-margin-start": (
      responsive: true,
      property: margin-left,
      class: ms,
      values: $negative-spacers,
    ),
    // Padding utilities
    "padding":
      (
        responsive: true,
        property: padding,
        class: p,
        values: $spacers,
      ),
    "padding-x": (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    "padding-y": (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    "padding-top": (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    "padding-end": (
      responsive: true,
      property: padding-right,
      class: pe,
      values: $spacers,
    ),
    "padding-bottom": (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    "padding-start": (
      responsive: true,
      property: padding-left,
      class: ps,
      values: $spacers,
    ),
  )
);

$rounds: (
  null: $border-radius,
  0: 0,
  1: 4px,
  2: 8px,
  3: 16px,
  circle: 50%,
  pill: $border-radius-pill,
);

$utilities: map-merge(
  $utilities,
  (
    "rounded": (
      property: border-radius,
      class: rounded,
      values: $rounds,
    ),
    "rounded-top": (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: $rounds,
    ),
    "rounded-end": (
      property: border-top-right-radius border-bottom-right-radius,
      class: rounded-end,
      values: $rounds,
    ),
    "rounded-bottom": (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: $rounds,
    ),
    "rounded-start": (
      property: border-bottom-left-radius border-top-left-radius,
      class: rounded-start,
      values: $rounds,
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed,
    ),
  )
);

$breadcrumb-item-padding-x: 8px;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider-color: $gray;
$breadcrumb-active-color: $gray;
$breadcrumb-divider: quote("/");
