@import "variables";
@import "mixins";

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $surface-100 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
  margin: 0;
}

button.btn.primary {
  @extend %body-bold;
  height: 56px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background: $primary;
  padding: 14px 26px;
  border-radius: 8px;
  i {
    margin-left: 8px;
  }
  &:hover {
    background: $hover-button;
    color: $white;
  }
  &:active {
    background: $active-button;
    color: $white;
  }
  outline: none;
  box-shadow: none;
}

.modal-warper {
  .header {
    @extend %h3-md;
    color: $dark;
  }
}

.view {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .progress-bar {
    height: 4px;
    border-radius: unset;
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 1000;
  }
}

.table-wrapper {
  overflow-y: auto;
}

.filter-container {
  background-color: transparent;
  display: flex;
  margin: 20px;
  margin-bottom: 4px;

  &__btn {
    cursor: pointer;
    font-size: 21px;
    background-color: $surface-0 !important;
    color: $surface-900;
    border: 0;
    margin-left: 24px;
  }
}

.search-warper {
  position: relative;
  &__input {
    @extend %body-md;
    color: $surface-600;
    width: 376px;
    height: 48px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 16px;
    padding-left: 44px;
  }
  &__icon {
    position: absolute;
    left: 11px;
    top: 16px;
  }
}

.filter-dropdown-wrapper {
  position: relative;
  margin-left: 24px;
  color: $surface-700;
  align-items: center;

  i {
    padding: 0 7px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    line-height: 48px;
  }

  .filter-dropdown {
    padding: 8px 8px 8px 36px;

    height: 48px;

    background: $surface-0;
    border-radius: 8px;
    border: none;
    align-items: center;
  }
}

.loading-or-empty-table {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-wrapper {
  height: 100%;
  overflow-y: auto;
}

:host ::ng-deep .p-datatable-table {
  border-radius: 16px !important;
  overflow: hidden !important;
}
